<template>
  <div class="modifyPassword" >
    <div class="container">
      <div class="row">
        <div v-if="passwordReseted" class="col-sm-8 offset-sm-2">
          <div class="gavc-card gavc-text-center gavc-padding-large">
            <div class="gavc-card__header">
              <h3 class="gavc-h3 gavc-margin-bottom"> <i class="gavc-icon gavc-icon-check"></i>  Mot de passe modifié </h3>
              <p class="gavc-question gavc-margin-bottom"> Votre mot de passe a bien été modifié ! </p>
           </div>
           <div class="gavc-card__content">
             <button class="gavc-btn" @click="$router.push('/')" style="white-space: normal;">
               Retour à l'accueil
             </button>
           </div>
            <div class="gavc-background-card-text">
              <p class="gavc-background-card-text__content"> mot de passe </p>
            </div>
          </div>
        </div>
        <div v-else class="col-8 offset-2">
          <b-form v-on:submit.prevent="modifyPassword()">
            <h3 class="gavc-h3 text-center mb-4"> Modification de votre mot de passe </h3>
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': oldPassword && $v.oldPassword.$dirty ? $v.oldPassword.$error : null  }">
                <passwordInput
                  id="old-password-input"
                  maxLength="100"
                  @value-changed="oldPassword = $event"
                  @input="$v.oldPassword.$touch()"
                  :defaultValue="oldPassword"
                  placeholder="Mot de passe actuel"
                />
                <p
                  v-if="errorOldPasswordMessage"
                  class="gavc-text-rose gavc-legende">
                  Ce mot de passe ne correspond pas à votre mot de passe actuel, veuillez réessayer
                </p>
            </div>

            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': newPassword && $v.newPassword.$dirty ? $v.newPassword.$error : null  }">
                <passwordInput
                  id="new-password-input"
                  maxLength="100"
                  @value-changed="newPassword = $event"
                  @input="$v.newPassword.$touch()"
                  :defaultValue="newPassword"
                  placeholder="Nouveau mot de passe"
                />
                <p
                  v-if="newPassword != '' && !$v.newPassword.password_validation"
                  class="gavc-text-rose gavc-legende">
                  Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial
                </p>
            </div>

            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': newPasswordConfirmation && $v.newPasswordConfirmation.$dirty ? $v.newPasswordConfirmation.$error : null  }">
                <passwordInput
                  id="new-password-confirm-input"
                  :maxLength="100"
                  @value-changed="newPasswordConfirmation = $event"
                  @input="$v.newPasswordConfirmation.$touch()"
                  :defaultValue="newPasswordConfirmation"
                  placeholder="Confirmation de votre nouveau mot de passe"
                />
                <p
                  v-if="newPasswordConfirmation != '' && !$v.newPasswordConfirmation.sameAsPassword"
                  class="gavc-text-rose gavc-legende">
                  Veuillez rentrer deux mots de passe identiques
                </p>
            </div>

            <div class="text-center">
              <button
                type="submit"
                class="gavc-btn mt-3"
                :disabled="$v.oldPassword.$invalid || $v.newPassword.$invalid || $v.newPasswordConfirmation.$invalid || isModifyingPassword">
                  Réinitialiser votre mot de passe
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, sameAs } from 'vuelidate/lib/validators'

import { APIDisconnectedService } from '@/api/APIDisconnected'
import { password_validation } from '@/validators/validators'

const passwordInput = () => import('@/components/inputs/passwordInput')

const apiAuth = new APIDisconnectedService()


export default {
  name: 'modifyPassword',
  components: {
    passwordInput
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      errorOldPasswordMessage: false,
      isModifyingPassword: false,
      newPassword: '',
      newPasswordConfirmation: '',
      oldPassword: '',
      passwordReseted: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      maxLength: maxLength(249),
      required,
      password_validation
    },
    newPasswordConfirmation: {
      maxLength: maxLength(249),
      required,
      sameAsPassword: sameAs('newPassword'),
    }
  },
  methods: {
    modifyPassword () {
      this.errorOldPasswordMessage = false
      this.isModifyingPassword = true
      this.$store.commit('loadFromAPI')
      apiAuth.modifyPassword(this.token, this.oldPassword, this.newPassword, this.newPasswordConfirmation)
      .then(() => {
          this.passwordReseted = true
        }, (error) => {
          if (error.response && error.response.data && error.response.data.old_password && error.response.data.old_password === "wrong_password") {
            this.errorOldPasswordMessage = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
            throw new Error('modifyPassword modifyPassword API Error : ' + String(error))
          }
        })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isModifyingPassword = false
      })
    },
  }
}
</script>
